import React from "react";
import Footer from "../components/Footer";
import search_ends from "../resources/images/search_ends.png";

function ContactPage() {
  return (
    <div className="mx-7 resp-mx-1r mt-8rem">
      <div className="d-flex mb-5rem resp-flex-reverse">
        <div className="d-flex flex-column w-50p resp-w-90p justify-content-center">
          <div
            className="font-poppins contact-options fz-20px"
            data-aos="fade-up"
          >
            Contact
          </div>
          <h1
            className="no-overflow font-poppins fw-bold text-left my-4"
            data-aos="fade-up"
          >
            What are you searching for ?
          </h1>
          <div className="font-poppins contact-options fz-35px resp-fz-25px mb-20px">
            Business Query
          </div>
          <div className="font-poppins contact-options fz-35px resp-fz-25px mb-20px">
            Contact Us
          </div>
          <div className="font-poppins contact-options fz-35px resp-fz-25px mb-20px">
            Apply For Job
          </div>
        </div>
        <div className="search-ends-img-container w-50p d-flex justify-content-center">
          <img src={search_ends} className="w-60p resp-w-100p" />
        </div>
      </div>
      <div className="d-flex">
          <div className="d-flex flex-column mx-2 font-poppins">
            <div className="my-1 state-name" style={{fontSize: "40px"}}>Lucknow <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" className="india_img"/></div>
            <div className="contact fz-25px resp-fz-20px my-1">+91 9044843903, +91 7905879063</div>
            <div className="email fz-25px resp-fz-20px my-1">mohanbhatt007@smarttekmedia.com</div>
            <div className="address fz-25px resp-fz-20px my-1">
              Skyline Plaza, Near Lulu Mall, Sushant Golf City, Lucknow.
            </div>
          </div>

        </div>
      <hr className="ending-hr" />
      <Footer />
    </div>
  );
}

export default ContactPage;
