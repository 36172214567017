import React, { useState, useEffect } from "react";
import logo from "../resources/images/smartTekLogo.png"

function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const calculateTranslation = () => {
    const scrollRange =
      document.documentElement.scrollHeight - window.innerHeight;
    const percentage = (scrollPosition / scrollRange) * 100;

    // Ensure the percentage is within the valid range (0% to 110%)
    return `${Math.min(120, Math.max(0, percentage))}%`;
  };

  const headingLeftStyle = {
    transform: `translateX(-${calculateTranslation()})`,
  };

  const headingRightStyle = {
    transform: `translateX(${calculateTranslation()})`,
  };

  return (
    <div>
      <div className="dummy_box" />
      <div
        className="heading-left fw-bold resp-d-none"
        style={headingLeftStyle}
      >
        Digital
      </div>
      <div
        className="heading-right fw-bold resp-d-none"
        style={headingRightStyle}
      >
        World
      </div>
      <div className="partner_with_us resp-d-none">
        <div className="d-flex flex-column align-items-center">
          <div className="arrow_svg my-2">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 16 16"
              height="2em"
              width="2em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
              ></path>
            </svg>
          </div>
          <div className="partner_text">Partner With Us</div>
        </div>
      </div>
      <div className="mx-3">
        <img src={logo} className="phone-content w-60p"/>
      <h1 className="phone-content fw-bold text-left font-poppins fz-36px">
        Are you ready for the real{" "}
        <span style={{ color: "#7855F8" }}>Digital Transformation?!</span>
      </h1>
      <h3 className="agency-text color-white font-poppins resp-my-2 small-w-90p resp-p-static resp-fz-18 gray-text">
        The only agency that covers your digital and creative needs together,
        efficiently!
      </h3>
      <button className="phone-content phone-partner my-4">Partner With Us <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"></path></svg></button>
      </div>
    </div>
  );
}

export default Home;
