import React from "react";
import logo from "../resources/images/smartTekLogo.png";

function Footer() {
  return (
    <div className="d-flex mt-5 my-4 resp-mx-0 mx-7 align-items-center justify-content-evenly resp-flex-column">
      <div className="logo d-flex align-items-center justify-content-center w-40p">
        <img src={logo} style={{ width: "200px" }} />
      </div>
      <div className="d-flex flex-column resp-mt-4r">
        <h1 className="no-overflow font-poppins resp-text-center py-2 text-left">
          You can find us
        </h1>
        <div className="d-flex">
          <div className="d-flex flex-column mx-2 font-poppins resp-align-center">
            <div className="state-name my-1">Lucknow</div>
            <div className="contact my-1 resp-fz-13px">+91 9044843903, +91 7905879063</div>
            <div className="email my-1 resp-fz-13px">mohanbhatt007@smarttekmedia.com</div>
            <div className="address my-1 resp-fz-13px">
              Skyline Plaza, Near Lulu Mall, Sushant Golf City, Lucknow.
            </div>
          </div>
          {/* <div className='d-flex flex-column mx-2 font-poppins'>
                <div className='state-name my-1'>Delhi</div>
                <div className='contact my-1'>+91123456789</div>
                <div className='email my-1'>info@deepdivemedia.in</div>
                <div className='address my-1'>
                5th Floor, 500, ITL Twin Tower, Netaji Subhash Place, Pitampura, North West Delhi, Delhi, 110034
                </div>
            </div>
            <div className='d-flex flex-column mx-2 font-poppins'>
                <div className='state-name my-1'>Delhi</div>
                <div className='contact my-1'>+91123456789</div>
                <div className='email my-1'>info@deepdivemedia.in</div>
                <div className='address my-1'>
                5th Floor, 500, ITL Twin Tower, Netaji Subhash Place, Pitampura, North West Delhi, Delhi, 110034
                </div>
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
