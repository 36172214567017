import React from "react";
import AboutUsSection_1 from "../components/About/AboutUsSection_1";
import GoogleCampaign from "../components/GoogleCampaign";
import Footer from "../components/Footer";
import LetsTalk from "../components/res/LetsTalk";
import image_1 from "../resources/images/affiliate-1.jpg";
import image_2 from "../resources/images/affiliate-2.jpg";
import image_3 from "../resources/images/affiliate-3.jpg";
import image_4 from "../resources/images/affiliate-4.jpg";
import AboutCarousel from "../components/About/AboutCarousel";

function AboutPage() {
  return (
    <div>
      <div className="aboutCarousel">
        <AboutCarousel images={[image_1, image_2, image_3, image_4]} />
      </div>
      <AboutUsSection_1 />
      <GoogleCampaign />
      <LetsTalk />
      <hr className="ending-hr" />
      <Footer />
    </div>
  );
}

export default AboutPage;
