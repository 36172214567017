import React from "react";
import RecentProject from "./res/RecentProjectCard";
import airline from "../resources/images/airline.webp";
import coupons from "../resources/images/coupons.webp";
import leadgen from "../resources/images/leadgen.webp";
import jobs from "../resources/images/jobs.webp";
import review from "../resources/images/review.webp";
import gaming from "../resources/images/gaming.webp";

function RecentProjects() {
  return (
    <div className="mt-10rem d-flex flex-column align-items-center resp-d-none">
      <h1 className="font-poppins recent-projects-head no-overflow text-center">
        Recent Projects
      </h1>
      <div className="projects p-relative d-flex flex-wrap justify-content-center no-overflow">
        {/* <div className='d-flex justify-content-center p-relative'> */}
        <RecentProject
          img={coupons}
          text="Coupons Sites"
          alignRight={true}
          id={"recent_1"}
        />
        <RecentProject img={airline} text="Travel Sites" id={"recent_2"} />
        {/* </div> */}
        {/* <div className='d-flex justify-content-center p-relative'> */}
        <RecentProject
          img={review}
          text="Reviews Sites"
          alignRight={true}
          id={"recent_3"}
        />
        <RecentProject img={leadgen} text="Lead Generation" id={"recent_4"} />
        {/* </div> */}
        {/* <div className='d-flex justify-content-center p-relative'> */}
        <RecentProject
          img={gaming}
          text="Games Creation"
          alignRight={true}
          id={"recent_5"}
        />
        <RecentProject img={jobs} text="Jobs Finder" id={"recent_1"} />
        {/* </div> */}
      </div>
      <button className="diving-deep-read-more-btn">
        MORE{" "}
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 16 16"
          height="1.2em"
          width="1.2em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
          ></path>
        </svg>
      </button>
    </div>
  );
}

export default RecentProjects;
