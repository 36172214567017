import React from "react";
import video from "../resources/services_video.mp4"
import ServicesSection from "../components/Services/ServicesSection";

function ServicesPage() {
  return (
    <div>
      <div className="about-video-container">
        <video src={video} className="about-video" autoPlay loop muted />
      </div>
      {/* <div className="down_arrow_container">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 24 24"
          height="2em"
          width="2em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M18 6.41L16.59 5 12 9.58 7.41 5 6 6.41l6 6z"></path>
          <path d="M18 13l-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"></path>
        </svg>
      </div> */}
      <ServicesSection/>
    </div>
  );
}

export default ServicesPage;
