import React from "react";
import image from "../../resources/images/human.png";

function AboutUsSection_1() {
  return (
    <div className="d-flex flex-column">
      <div className="mx-auto d-flex w-80p flex-column">
        <div className="d-flex flex-column align-items-center w-100p mx-auto">
          {/* <h1  className="font-poppins fw-bold no-overflow" data-aos="fade-up">About Us</h1 > */}
          <h1 className="font-poppins pt-2 text-center resp-fz-40px" data-aos="fade-up" data-aos-duration="1500">
          Smart Tek Media
          </h1>
        </div>
        <div className="d-flex pb-5 align-items-center about-sec resp-flex-reverse" data-aos="fade-right">
          <div className="d-flex flex-column w-100 mr-2rem about-content">
            <h2 className="no-overflow fw-bold w-100 fz-3r" id="about-us">
              COMPETITION AND CREATIVE RUNS HARD IN HIS VEINS
            </h2>
            <p className="about_us_paragraph text-left my-5 font-poppins">
              "For an organisation to lead the competitors, it is very important
              to Chase, Upgrade and Sustain. Chase the new challenges, Upgrade
              with the latest technology, and Sustain the calm. I try to push
              all my teammates in achieving so. Their zeal, in turn, pushes me
              to do the same. <br />
              <br />
              For me, SmartTek Media is merely not an organisation where
              everyone works behind the screens. It is an emotion that fulfils
              dreams of all those who are associated with it. For us, as a team,
              each and every effort is worthy of being ‘on screen."
            </p>
            <p className="font-poppins about_us_paragraph">
              <b>- Mohan Bhatt</b> <br /> <b>Co-Founder</b>{" "}
            </p>
          </div>
          <img src={image} className="w-70p resp-mt-3r human-img h-auto fz-3r" />
        </div>
      </div>
      <div className="details-section d-flex resp-m-0 resp-flex-column">
        <div className="d-flex flex-column w-40p resp-w-90p resp-m-auto mr-2rem ml-5rem">
          <h1 className="color-white font-poppins diving-deep-heading fw-bold">
            Numbers That Define Success!
          </h1>
          <p className="details-section-paragraph resp-text-center">
            These numbers are more than just figures – they symbolise our
            relentless pursuit of excellence. With a passion for exceeding
            expectations, we continuously chase milestones, ensuring client
            satisfaction and delivering projects on time. We believe in turning
            aspirations into tangible results, and our numbers speak volumes!
          </p>
        </div>
        <div className="cards d-flex flex-wrap justify-content-evenly mr-5rem resp-m-0">
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">Experience</div>
            <h3 className="card-detail font-poppins">10+</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">Team size</div>
            <h3 className="card-detail font-poppins">100+</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">
              Monthly global traffic
            </div>
            <h3 className="card-detail font-poppins">90M</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">
              monthly conversions
            </div>
            <h3 className="card-detail font-poppins">2.5M</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">Monthly gmv</div>
            <h3 className="card-detail font-poppins">2B</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">clients</div>
            <h3 className="card-detail font-poppins">5000+</h3>
          </div>
        </div>
      </div>
      {/* <div className="team-expertise d-flex flex-column">
        <h1 className="expertise-heading">
          <span style={{ color: "yellow" }}>*</span> A powerhouse{" "}
          <span
            style={{
              textDecoration: "underline",
              textDecorationColor: "yellow",
            }}
          >
            team with expertise,
          </span>{" "}
          delivering{" "}
          <span style={{ backgroundColor: "yellow", color: "black" }}>
            amazing results!
          </span>
        </h1>
        <p
          style={{ marginLeft: "5rem" }}
          className="about_us_paragraph w-70p font-poppins"
        >
          Our team is a dynamic blend of seasoned professionals and rising
          stars, bringing a wealth of specialised knowledge to deliver
          exceptional results.
        </p>
      </div> */}
      {/* <div className="expertise-cards d-flex flex-wrap"> */}
      {/* <AboutSectionCard/> */}
      {/* </div> */}
    </div>
  );
}

export default AboutUsSection_1;
