import React from "react";
import service_img from "../../resources/images/service.png";
import girl from "../../resources/images/standinggirl1.webp";
import influencer from "../../resources/images/influencer.svg";
import content from "../../resources/images/content.svg";
import email from "../../resources/images/email.svg";
import gaming from "../../resources/images/Gaming.svg";
import media from "../../resources/images/media.svg";
import protection from "../../resources/images/Protection.svg";
import social from "../../resources/images/social.svg";
import video from "../../resources/images/video.svg";
import ServicesCard from "./../res/ServicesCard";
import LetsTalk from "../res/LetsTalk";
import Footer from "../Footer";

function ServicesSection() {
  return (
    <div className="d-flex flex-column">
      <div className="mx-auto d-flex w-80p flex-column" data-aos="fade-up" data-aos-duration="1500">
        <div className="d-flex flex-column align-items-center w-80p resp-w-90p mx-auto">
          <h1 className="font-poppins fw-bold no-overflow">Our Services</h1>
          <div className="font-poppins py-2 about_us_paragraph text-center">
            We strive to bring cutting edge innovation and transform the digital
            marketing space with effective marketing strategies and services.
            Our efforts are focused to revolutionise the approach towards
            branding and online marketing horizon, for enhanced user experience
            along with result oriented solutions for the advertisers.
          </div>
        </div>
      </div>
      <div className="d-flex resp-flex-column mx-auto w-80p mt-5rem p-relative">
        <div className="sticky-image-container">
          <div className="d-flex flex-column sticky-image-container py-5">
            <div className="services-img-container">
              <img src={service_img} className="services-img" />
            </div>
            <h1 className="font-poppins text-left no-overflow w-100 services-head">
              We DIVE to FETCH the best digital marketing services
            </h1>
            <div className="texts">
              <div className="about_us_paragraph font-poppins text-left">
                - Seamless services that drive conversions.
              </div>
              <div className="about_us_paragraph font-poppins text-left">
                - Innovative strategies, proven results.
              </div>
              <div className="about_us_paragraph font-poppins text-left">
                - Smart techniques, quick implementations, & phenomenal results!
              </div>
            </div>
          </div>
        </div>
        <div className="services-right d-flex flex-wrap w-100">
          <ServicesCard
            widthFull={true}
            heading="Media Buying"
            img={media}
            text={
              "We help you achieve hands-on results by conducting extensive research and planning. We use our expertise to identify the right channels for advertising and optimize your advertising budget to increase your ROI"
            }
          />
          <ServicesCard
            widthFull={true}
            heading="Email Marketing"
            img={email}
            text={
              "We excel in strategic email marketing that delivers tangible results. Our team of experts uses the latest tools and technology to create customized email campaigns that resonate with your target audience, increase your open rates, and drive conversions."
            }
          />
          <ServicesCard
            widthFull={true}
            heading="Social Media Marketing"
            img={social}
            text={
              "We understand that social media marketing is a powerful tool for building your brand and driving sales. Our team uses cutting-edge social media strategies to increase your brand awareness, engage your audience, and drive conversions. We are experienced in all major social media platforms, including Facebook, Instagram, Twitter, and LinkedIn."
            }
          />
          <ServicesCard
            widthFull={true}
            heading="Influencers Marketing"
            img={influencer}
            text={
              "We specialize in building fruitful collaborations with influencers that align with your brand values and goals. Our team works with influencers in your industry to create innovative marketing campaigns that increase your brand awareness and drive conversions. We handle everything from identifying the right influencers to managing the campaign and measuring its success."
            }
          />
          <ServicesCard
            widthFull={true}
            heading="Content Marketing"
            img={content}
            text={
              "Our smart content strategies are tailored to your unique marketing goals and objectives. We create high-quality content that engages your audience and positions your brand as a thought leader in your industry. Our content marketing services include blog writing, social media content, video production, and more."
            }
          />
          <ServicesCard
            widthFull={true}
            heading="Video And Branding"
            img={video}
            text={
              "We believe that video is one of the most effective ways to build your brand and engage your audience. Our team of video production experts creates high-quality videos that capture your brand's essence and convey your message effectively. We help you leverage the power of video to tell your story, showcase your products or services, and build your brand identity."
            }
          />
          <ServicesCard
            widthFull={true}
            heading="Gaming"
            img={gaming}
            text={
              "We employ advanced strategies and data-driven techniques to identify and capture high-quality leads that are most likely to convert. We deliver a consistent stream of qualified leads, enabling you to boost your revenue."
            }
          />
          <ServicesCard
            widthFull={true}
            heading="Google Ad Protection"
            img={protection}
            text={
              "Our team of talented developers, designers, and creative minds collaborate to bring captivating gaming experiences to life. With a deep understanding of the gaming industry and its ever-evolving landscape, we strive to create games that leave a lasting impact on players. We embrace the latest technologies and trends, ensuring that our games are at the forefront of the industry."
            }
          />
        </div>
      </div>
      <LetsTalk />
      <hr className="ending-hr" />
      <Footer />
    </div>
  );
}

export default ServicesSection;
