import React from "react";
import banner from "../resources/images/bannerblack.png";
import google from "../resources/images/googleapproved.png";

function GoogleCampaign() {
  return (
    <div className="bg-black">
      <div>
        <h1 className="campaign-heading font-poppins no-overflow w-50p resp-w-90p mx-auto text-center mt-5rem fw-bold mb-2rem">
          Defeat <span>Click Fraud</span>, Supercharge Your{" "}
          <span>Google Ads Campaigns</span>
        </h1>
        <p className="campaign-paragraph font-poppins text-center resp-w-90p resp-fz-18">
          Eradicate click fraud, block bots, and optimize ad spend for
          remarkable results. Shield your campaigns with our cutting-edge
          techniques.
        </p>
      </div>
      <div className="d-flex flex-column images-container">
        <img src={banner} className="resp-w-90p"/>
        <img src={google} className="resp-w-60p"/>
      </div>
    </div>
  );
}

export default GoogleCampaign;
