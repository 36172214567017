import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../resources/images/smartTekLogo.png";
import first_img from "../resources/images/nav1.webp";
import second_img from "../resources/images/nav4.png";
import third_img from "../resources/images/nav3.png";
import fourth_img from "../resources/images/contact.png";
import close from "../resources/images/close.png";

function Navbar() {
  const [isActive, setIsActive] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [activePage, setActivePage] = useState("/");

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleMouseEnter = (index) => () => {
    setImgIndex(index);
  };

  const handleMouseLeave = () => {
    setImgIndex(0);
  };

  const handleLinkClick = (path) => {
    if (path !== activePage) {
      toggleMenu();
    }
  };

  useEffect(() => {
    if (imgIndex === 1) {
      document.querySelectorAll("li.no-overflow")[0].style.color = "#fee7c0";
      document.getElementsByClassName("overlay")[0].style.background =
        "#f1ca89";
    } else if (imgIndex === 2) {
      document.querySelectorAll("li.no-overflow")[1].style.color = "#c8bf96";
      document.getElementsByClassName("overlay")[0].style.background =
        "#a69d70";
    } else if (imgIndex === 3) {
      document.querySelectorAll("li.no-overflow")[2].style.color = "#877777";
      document.getElementsByClassName("overlay")[0].style.background =
        "#fef9c3";
    } else if (imgIndex === 4) {
      document.querySelectorAll("li.no-overflow")[3].style.color = "#d16d8e";
      document.getElementsByClassName("overlay")[0].style.background =
        "#ffc9d9";
    } else {
      document.getElementsByClassName("overlay")[0].style.background = "#fff";
      document.querySelectorAll("li.no-overflow")[0].style.color = "#000";
      document.querySelectorAll("li.no-overflow")[1].style.color = "#000";
      document.querySelectorAll("li.no-overflow")[2].style.color = "#000";
      document.querySelectorAll("li.no-overflow")[3].style.color = "#000";
    }

    // Set active page when component mounts
    setActivePage(window.location.pathname);
  }, [imgIndex]);

  return (
    <div className="navbar">
      <div className={`overlay ${isActive ? "open" : ""}`}>
        <nav className="overlay-menu no-overflow">
          <ul className="p-relative">
            {imgIndex === 1 && (
              <img
                src={first_img}
                alt={`Image ${imgIndex}`}
                style={{ zIndex: imgIndex === 1 ? 1 : 0 }}
              />
            )}
            {imgIndex === 2 && (
              <img
                src={second_img}
                alt={`Image ${imgIndex}`}
                style={{ zIndex: imgIndex === 2 ? 1 : 0 }}
              />
            )}
            {imgIndex === 3 && (
              <img
                src={third_img}
                alt={`Image ${imgIndex}`}
                style={{ zIndex: imgIndex === 3 ? 1 : 0 }}
              />
            )}
            {imgIndex === 4 && (
              <img
                src={fourth_img}
                alt={`Image ${imgIndex}`}
                style={{ zIndex: imgIndex === 4 ? 1 : 0 }}
              />
            )}
            <Link to="/" onClick={() => handleLinkClick("/")}>
              <li
                className="no-overflow lh-120"
                onMouseEnter={handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
                style={{ zIndex: imgIndex === 1 ? 2 : 0, padding: "0 1rem" }}
              >
                Home
              </li>
            </Link>
            <Link to="/about" onClick={() => handleLinkClick("/about")}>
              <li
                className="no-overflow lh-120"
                onMouseEnter={handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
                style={{ zIndex: imgIndex === 2 ? 2 : 0, padding: "0 1rem" }}
              >
                About
              </li>
            </Link>
            <Link to="/services" onClick={() => handleLinkClick("/services")}>
              <li
                className="no-overflow lh-120"
                onMouseEnter={handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
                style={{ zIndex: imgIndex === 3 ? 2 : 0, padding: "0 1rem" }}
              >
                Service
              </li>
            </Link>
            <Link to="/contact" onClick={() => handleLinkClick("/contact")}>
              <li
                className="no-overflow lh-120"
                onMouseEnter={handleMouseEnter(4)}
                onMouseLeave={handleMouseLeave}
                style={{ zIndex: imgIndex === 4 ? 2 : 0, padding: "0 1rem" }}
              >
                Contact
              </li>
            </Link>
          </ul>
        </nav>
        <div
          className="d-flex flex-column cross-button-container align-items-center"
          onClick={toggleMenu}
        >
          <img
            src={close}
            className="cross-icon"
            alt="Close Icon"
          />
          <div className="close-text">CLOSE</div>
        </div>
      </div>
      <div className="d-flex p-3 m-2 mx-5 resp-mx-2 justify-content-between w-100">
        <img className="logo" src={logo} alt="Logo" />
        <div className="menu">
          <div className="lines d-flex flex-column" onClick={toggleMenu}>
            <div className="line line_1"></div>
            <div className="line line_2"></div>
            <div className="line line_3"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
