import React from "react";
import client1 from "../resources/images/client1.svg";
import client2 from "../resources/images/client2.svg";
import client3 from "../resources/images/client3.svg";
import client4 from "../resources/images/client4.svg";
import client5 from "../resources/images/client5.svg";
import client6 from "../resources/images/client6.svg";
import client7 from "../resources/images/client7.svg";
function Partners() {
  return (
    <div className="mx-5rem resp-m-0 resp-mt-6r mt-10rem partners-section d-flex p-relative overflow-initial align-items-center resp-flex-column">
      <div className="left-partners resp-w-70p w-50p" data-aos="fade-down" data-aos-duration="2000">
        <h1 className="no-overflow font-poppins resp-w-100p fw-bold text-left w-60p">Our Partners In Progres</h1>
        <p className="my-4 partners-paragraph resp-w-100p w-60p">
          We are proud to be associated with an exclusive network of industry
          leaders. Our commitment to delivering exceptional results, fostering
          strong relationships, and staying at the forefront of innovation sets
          us apart. We prioritise building long-lasting relationships based on
          trust, reliability, and a deep understanding of their business needs.
        </p>
      </div>
      <div className="clients-container p-relative w-60p" data-aos="fade-up" data-aos-duration="2000">
        <img src={client1} className="img_1"/>
        <img src={client2} className="img_2"/>
        <img src={client3} className="img_3"/>
        <img src={client4} className="img_4"/>
        <img src={client5} className="img_5"/>
        <img src={client6} className="img_6"/>
        <img src={client7} className="img_7"/>
      </div>
    </div>
  );
}

export default Partners;
