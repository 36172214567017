import React from "react";

function ServicesCard({ fade, img, heading, text, widthFull }) {
  return (
    <div
      className={`service-card d-flex flex-column resp-w-100p m-3 ${
        widthFull ? "w-100" : "w-45p"
      } `}
      data-aos={`${fade ? "fade-up" : ""}`} data-aos-duration="800"
    >
      <img src={img} className="service-card-img" />
      <h3 className="service-card-head no-overflow font-poppins fw-bold">
        {heading}
      </h3>
      <div
        className={`service-card-text ${
          widthFull ? "color-white" : ""
        } font-poppins`}
      >
        {text}
      </div>
    </div>
  );
}

export default ServicesCard;
