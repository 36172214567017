import React from "react";

function LetsTalk() {
  return (
    <div className="mx-7 resp-mx-0 resp-ml-1r resp-mt-6r mt-10rem d-flex resp-flex-column">
      <div className="d-flex flex-column lets-talk-container">
        <div
          className="lets-talk-text text-left"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          let's
        </div>
        <div
          className="lets-talk-text resp-text-center text-right"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          talk
        </div>
      </div>
      <div className="d-flex flex-column w-40p resp-w-100p resp-mt-3r">
        <div className="lets-talk-paragraph font-poppins">
          Unlock your digital marketing potential today! Contact us today and
          let us drive your online success.
        </div>
        <button className="diving-deep-read-more-btn">READ MORE</button>
      </div>
    </div>
  );
}

export default LetsTalk;
