import React, { useState } from "react";

function RecentProject({ text, img, alignRight, id }) {
  const [isHovered, setHovered] = useState(false);

  const handleMouseMove = (e) => {
    const container = document.querySelector(".project-image-container");
    const rect = container.getBoundingClientRect();
  
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
  
    const translateX = offsetX / rect.width - 0.5;
    const translateY = offsetY / rect.height - 0.5;
  
    container.style.transform = `translate(${translateX * 30}px, ${translateY * 30}px)`;
  };
  
  return (
    <div
      className={`w-40p d-flex ${alignRight ? "justify-content-end" : "justify-content-start"}`}
      onMouseMove={handleMouseMove}
    >
      <div
        className={`project-image-container no-overflow ${
          isHovered ? "visible" : "hidden"
        }`}
        id={id}
        style={{ transform: "translate(0, 0)" }}
      >
        <img src={img} className="projects-image" />
      </div>
      <div
        className="mx-4 p-relative no-overflow"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => {
          setHovered(false);
          const container = document.querySelector(`#${id}`);
          container.style.transform = "translate(0, 0)";
        }}
      >
        <div className="project_name_container no-overflow" data-text={text}>
          {text}
        </div>
      </div>
    </div>
  );
  
}

export default RecentProject;
