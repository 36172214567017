import React from "react";
import girl from "../resources/images/standinggirl1.webp";
import influencer from "../resources/images/influencer.svg"
import content from "../resources/images/content.svg"
import email from "../resources/images/email.svg"
import gaming from "../resources/images/Gaming.svg"
import media from "../resources/images/media.svg"
import protection from "../resources/images/Protection.svg"
import social from "../resources/images/social.svg"
import video from "../resources/images/video.svg"
import ServicesCard from "./res/ServicesCard";
function Services() {
  return (
    <div className="no-overflow mt-10rem resp-mt-6r resp-m-0 mx-7 d-flex resp-flex-column">
      <div className="services-left d-flex flex-column w-fit-content w-50p resp-w-100p resp-align-center">
        <img src={girl} className="services-girl resp-m-auto" />
        <div className="d-flex flex-column w-60p resp-w-100p m-4 resp-m-0">
          <div className="resp-d-none">
          <h1 className="no-overflow font-poppins text-center fw-bold">
            Built for the
          </h1>
          <h1 className="no-overflow font-poppins text-center fw-bold">
            future of B2B
          </h1>
          <h1 className="no-overflow font-poppins text-center fw-bold">
            marketing.
          </h1>
          </div>
          <h1 className="no-overflow phone-content font-poppins text-center fw-bold resp-fz-19px">
            Built for the future of B2B marketing.
          </h1>
          <p className="text-center services-para font-poppins resp-d-none">We transform B2B marketing <br/>complexities into seamless digital<br/> solutions with expertise!</p>
          <p className="text-center services-para font-poppins phone-content resp-fz-15px mx-2">B2B marketing is complex. Stryve has built a team of full-stack marketers, designers, and developers who understand what companies need to grow quickly.</p>
        </div>
      </div>
      <div className="services-right d-flex flex-wrap w-50p resp-w-100p">
        <ServicesCard fade={true} heading = "Media Buying" img={media} text={"Get hands on results with research, planning, and rising ROI with us!."}/>
        <ServicesCard fade={true} heading = "Email Marketing" img={email} text={"Strategic Email marketing isn’t everyone’s forte. For us, it is indeed!"}/>
        <ServicesCard fade={true} heading = "Social Media Marketing" img={social} text={"Social media marketing is the secret of success, and we aim only at such success."}/>
        <ServicesCard fade={true} heading = "Influencers Marketing" img={influencer} text={"Fruitful collabs, best endorsements, and innovative marketing is our cup of tea!"}/>
        <ServicesCard fade={true} heading = "Content Marketing" img={content} text={"Smart content strategies for best marketing solutions is our expertise."}/>
        <ServicesCard fade={true} heading = "Video And Branding" img={video} text={"Capturing the best marketing and branding is something we never run away from."}/>
        <ServicesCard fade={true} heading = "Gaming" img={gaming} text={"Our talented team of developers, designers, and creative minds bring gaming vision to life via best technology."}/>
        <ServicesCard fade={true} heading = "Google Ad Protection" img={protection} text={"With our strategies, we help you tackle click fraud head-on, safeguarding your advertising investments and driving meaningful results."}/>
      </div>
    </div>
  );
}

export default Services;
