import React, { useState, useEffect } from "react";
import girl from "../resources/images/girl2.webp";

function DivingDeep() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const translateX =
    scrollPosition / 80 < 13 ? `-${scrollPosition / 80}rem` : -13; // Adjust the division factor as needed

  return (
    <div className="p-relative no-overflow mt-10rem">
      <div className="d-flex flex-column w-40p resp-w-100p resp-m-0 resp-pl-1r mr-2rem ml-5rem">
        <h1 className="color-white font-poppins diving-deep-heading fw-bold text-left w-80p">
          DIVING DEEP into digital depths!
        </h1>
        <p className="diving-deep-paragraph my-3">
          We, at SmartTek Media, design performance oriented marketing
          strategies to help Advertisers & Brands grow online. We curate the
          customised marketing strategies to help our clients, advertisers and
          brands expand their reach and profits online. With the family of 50+
          teammates, we aim to with the agenda of providing a better ROI.
        </p>
        <button className="diving-deep-read-more-btn">READ MORE</button>
      </div>

      <div className="details-section d-flex mt-10rem resp-flex-column">
        <div className="d-flex flex-column w-40p resp-w-90p resp-m-auto mr-2rem ml-5rem">
          <h1 className="color-white font-poppins diving-deep-heading fw-bold">
            Numbers That Define Success!
          </h1>
          <p className="details-section-paragraph resp-text-center">
            These numbers are more than just figures – they symbolise our
            relentless pursuit of excellence. With a passion for exceeding
            expectations, we continuously chase milestones, ensuring client
            satisfaction and delivering projects on time. We believe in turning
            aspirations into tangible results, and our numbers speak volumes!
          </p>
        </div>
        <div className="cards d-flex flex-wrap justify-content-evenly mr-5rem resp-m-0">
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">Experience</div>
            <h3 className="card-detail font-poppins">10+</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">Team size</div>
            <h3 className="card-detail font-poppins">100+</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">
              Monthly global traffic
            </div>
            <h3 className="card-detail font-poppins">90M</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">
              monthly conversions
            </div>
            <h3 className="card-detail font-poppins">2.5M</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">Monthly gmv</div>
            <h3 className="card-detail font-poppins">2B</h3>
          </div>
          <div className="detail-card d-flex flex-column align-items-center m-3 justify-content-center">
            <div className="card-heading fw-bold font-poppins">clients</div>
            <h3 className="card-detail font-poppins">5000+</h3>
          </div>
        </div>
      </div>
      <img
        src={girl}
        id="girl"
        style={{ transform: `translateX(${translateX})` }}
        className="resp-d-none"
      />
      <hr className="ending-hr" />
    </div>
  );
}

export default DivingDeep;
