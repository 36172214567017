import React from "react";
import DivingDeep from "./../components/DivingDeep";
import Footer from "./../components/Footer";
import GoogleCampaign from "./../components/GoogleCampaign";
import Home from "./../components/Home";
import LeaveReq from "./../components/LeaveReq";
import Partners from "./../components/Partners";
import RecentProjects from "./../components/RecentProjects";
import Services from "./../components/Services";
import Testimonial from "./../components/Testimonial";

function HomePage() {
  return (
    <>
      <Home />
      <DivingDeep />
      <GoogleCampaign />
      <Partners />
      <Testimonial />
      <Services />
      <RecentProjects />
      <LeaveReq />
      <Footer />
    </>
  );
}

export default HomePage;
