import React from "react";

function LeaveReq() {
  return (
    <div>
      <div className="d-flex mx-7 my-6 resp-mx-2 align-items-center resp-flex-column">
        <div
          className="d-flex flex-column w-55p resp-w-100p"
          data-aos="fade-up"
        >
          <div className="leave-a-req">Leave a request</div>
          <h1 className="no-overflow leave-a-req-h1">
            We'd love to initiate a conversation! Feel free to share your brief
            with us.
          </h1>
        </div>
        <button className="phone-content phone-partner my-4">
          Partner With Us{" "}
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
            ></path>
          </svg>
        </button>
        <div className="partner_with_us top-auto r-10p resp-d-none">
          <div className="d-flex flex-column align-items-center">
            <div className="arrow_svg my-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="2em"
                width="2em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                ></path>
              </svg>
            </div>
            <div className="partner_text">Partner With Us</div>
          </div>
        </div>
      </div>
      <hr className="ending-hr" />
    </div>
  );
}

export default LeaveReq;
