import React from "react";
import globe from "../resources/globe.mp4";
import Carousel from "./TestimonialCarousel";
import partner1 from "./../resources/images/Pat-Pat.png";
import partner2 from "./../resources/images/Aosom.png";

function Testimonial() {
  const testimonialsData = [
    {
      text: "At PatPat, We Always Find Many Coupons Website Partners To Share Our Specific Discount. Working With DeepDive Media, We Share Many Coupon Codes To Our Customers, We Gain More New Customers And Better Performance Together. Happy To Keep Long- Term Collaboration With DDM.",
      author: "Jenny Chen",
      position: "Affiliate Marketing Managaer",
      image: partner1,
      brandName: "PATPAT",
    },
    {
      text: "Thanks For Helping Introduce Our Website And Bringing Us More Sales Good Job! Appreciate Your Efforts To Drive Traffic. It's A Great Pleasure Working With You.",
      author: "Scarlett",
      position: "Affiliate Marketing Team",
      image: partner2,
      brandName: "AOSOM.C",
    },
  ];
  return (
    <div className="mt-10rem mx-5rem">
      <div className="card-heading fw-bold text-center font-poppins resp-d-none">
        WHAT CLIENTS SAY
      </div>
      <h1 className="font-poppins fw-bold text- my-4 text-center no-overflow resp-d-none">
        Testimonials
      </h1>
      <div className="d-flex justify-content-between resp-flex-column">
        <div className="testimonial w-60p resp-d-none">
          <Carousel testimonials={testimonialsData} />
        </div>
        <video src={globe} id="globe-video" className="resp-w-100p" autoPlay muted loop />
      </div>
    </div>
  );
}

export default Testimonial;
